<template>
  <div
    id="header"
    class="header"
    :class="[`header--${backgroundColor}`, { 'has-border': hasBorder }]">
    <div class="header__left">
      <ButtonsIconButton
        v-if="hasBackButton"
        icon="arrow-left"
        :label="contentStore.content?.back ?? ''"
        @click="$emit('backClick')" />
    </div>
    <h1
      v-if="title"
      class="header__title"
      :class="{
        'd-flex items-center gap-1': hasCartIndicator,
        'header__title--uppercase': titleIsUppercase,
      }">
      {{ title }}
      <CartBasketIndicator v-if="count > 0 && hasCartIndicator" />
    </h1>
    <NuxtLink v-else-if="hasLogo" to="/">
      <NuxtImg v-if="logo" format="webp" :src="logo" :width="169" alt="logo" />
      <ImagesSvg v-else icon="logo" class="d-block" />
    </NuxtLink>
    <div v-else></div>
    <div class="header__right" :class="{ 'logged-in': status === 'authenticated' }">
      <!-- Default show login button, can be overwritten with slot to show other buttons -->
      <slot v-if="hasRightButton">
        <ButtonsIconButton
          :label="contentStore.content?.profile ?? ''"
          :label-visible="true"
          :icon="status === 'authenticated' ? 'user-logged-in' : 'user'"
          :color="status === 'authenticated' ? 'green' : 'black'"
          @click="$router.push(routes.myAccount.path)" />
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';

const { status } = useAuth();
const basketStore = useBasketStore();
const { count } = storeToRefs(basketStore);

const { getLogo } = useContentApi();
const { data: logo } = await useLazyAsyncData('logo', () => getLogo());

export interface HeaderProps {
  hasBackButton?: boolean;
  title?: string;
  hasRightButton?: boolean;
  backgroundColor?: 'white' | 'gray' | 'green';
  hasBorder?: boolean;
  hasLogo?: boolean;
  hasCartIndicator?: boolean;
  titleIsUppercase?: boolean;
}

withDefaults(defineProps<HeaderProps>(), {
  hasBackButton: true,
  title: undefined,
  hasRightButton: true,
  backgroundColor: 'white',
  hasBorder: true,
  hasLogo: false,
  hasCartIndicator: false,
  titleIsUppercase: false,
});

defineEmits<{
  (e: 'backClick'): void;
}>();

const contentStore = useContentStore();
</script>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-items: center;
  align-items: center;
  padding-inline: $size-md;
  min-height: $header-height;
  z-index: 2;
  overflow: hidden;

  &__left {
    display: flex;
    justify-self: start;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
  }

  &__title {
    font-weight: 700;
    font-size: px(16);

    &--uppercase {
      text-transform: uppercase;
      max-width: 85%;
      @include truncate-ellipsis(85%);
    }
  }

  &--white {
    background-color: $c-white;

    &.has-border {
      border-bottom: 1px solid $c-neutral-4;
    }
  }

  &--gray {
    background-color: $c-neutral-1;
  }

  &--green {
    background-color: $c-green-3;
    color: $c-white;
  }
}
</style>
